import { DeleteOutline } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import serverUrl from "../../server";
import { setCollectionOption, setCollections } from "../../state";

// const compareProductsByCollection = (a, b) => {
//   if (a.productCollection < b.productCollection) {
//     return -1;
//   } else if (a.productCollection > b.productCollection) {
//     return 1;
//   } else {
//     return 0;
//   }
// };

export default function Collection() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);

  const collections = useSelector((state) => state.collections);
  const products = useSelector((state) => state.products);
  const collectionOption = useSelector((state) => state.collectionOption);

  function returnCollectionFirstProductImage(collectionName) {
    const collection = collectionOption.find(
      (singleCollection) => singleCollection.collectionName === collectionName
    );

    if (collection) {
      const firstProductId = collection.collectionProductsIds[0];
      const firstProduct = products.find(
        (product) => product._id === firstProductId
      );
      return firstProduct;
    }

    return null; // or any other appropriate fallback value if collection is not found
  }
  const handleDelete = async (_id) => {
    try {
      // Send a request to your server to delete the collection
      await fetch(`${serverUrl}/api/collection/delete/${_id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      }).then(async (res) => {
        if (res.ok) {
          // Fetch the updated collection list from the server (optional)
          const response = await fetch(`${serverUrl}/api/collection`);
          if (!response.ok) {
            throw new Error("Failed to fetch collections");
          }
          // Update the state with the new collection list
          // dispatch(setCollections({ collections: [] }));
          dispatch(setCollectionOption({ collectionOption: [] }));
          toast.success("Collection Successfully Deleted", {
            position: "top-center",
          });
        } else {
          toast.error("Error deleting collection", {
            position: "top-center",
          });
        }
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error deleting collection", {
        position: "top-center",
      });
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "collectionName",
      headerName: "Collection Name",
      width: 400,
      renderCell: (params) => {
        const firstProduct = returnCollectionFirstProductImage(
          params.row.collectionName
        );
        const productImage = firstProduct
          ? firstProduct.productImages[0]
          : null;

        return (
          <ProductListItem>
            {productImage && (
              <ProductListImg src={productImage} alt="Item Image" />
            )}
            {params.row.collectionName
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </ProductListItem>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/collections/" + params.row.collectionName}>
              <ProductListEditButton>View</ProductListEditButton>
            </Link>
            <ProductListDeleteIcon
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <ProductListContainer>
      {collectionOption.length < 1 ? (
        <>
          <ProductListTitleContainer>
            <ProductListTitle className="text-3xl font-bold">
              Collections
            </ProductListTitle>
            <Link to="/newcollection">
              <ProductAddButton>Add New Collection</ProductAddButton>
            </Link>
          </ProductListTitleContainer>
          <div className="flex justify-center items-center min-h-screen gap-3">
            <p> Seems like there's no collection 🤔, Try adding one... </p>
            <CircularProgress sx={{ color: "#b8a06a" }} />
          </div>
        </>
      ) : (
        <>
          <ProductListTitleContainer>
            <ProductListTitle className="text-3xl font-bold">
              Collections
            </ProductListTitle>
            <Link to="/newcollection">
              <ProductAddButton>Add New Collection</ProductAddButton>
            </Link>
          </ProductListTitleContainer>
          <DataGrid
            rows={collectionOption}
            disableSelectionOnClick
            columns={columns}
            pageSize={8}
            autoHeight
            checkboxSelection
          />
          <Toaster />
        </>
      )}
    </ProductListContainer>
  );
}

const ProductListContainer = styled.div`
  flex: 4;
`;

const ProductListItem = styled.div`
  display: flex;
  align-items: center;
`;
const ProductListImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;
const ProductListEditButton = styled.button`
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
`;
// const ProductListDeleteIcon = styled(DeleteOutline)`
//   color: red;
//   cursor: pointer;
// `;
const ProductListTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
const ProductListTitle = styled.h1``;
const ProductAddButton = styled.button`
  border: none;
  padding: 10px;
  background-color: #b8a06a;
  color: black;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;
const ProductListDeleteIcon = styled(DeleteOutline)`
  color: red;
  cursor: pointer;
`;
