import { CircularProgress } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import serverUrl from "../../server";
import { setLogin } from "../../state";
import "./Login.css";

const validate = yup.object().shape({
  email: yup.string().required("required"),
  password: yup.string().required("required"),
});
export default function Login() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  const values = {
    email: "",
    password: "",
  };
  const dispatch = useDispatch();

  const handleFormSubmit = async (values, onSubmitProps) => {
    console.log(values);
    setDisabled(!disabled);

    const loggedInResponse = await fetch(`${serverUrl}/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    });
    const loggedIn = await loggedInResponse.json();
    onSubmitProps.resetForm();
    if (loggedInResponse.ok) {
      dispatch(
        setLogin({
          user: loggedIn.user,
          token: loggedIn.token,
        })
      );
      navigate("/products");
      setDisabled(false);
    } else {
      console.log(loggedIn);
      setDisabled(false);
      toast.error(`${loggedIn.error}`, { position: "top-right" });
    }
  };
  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-brandColor rounded-md shadow-2xl hover:shadow-none shadow-black  ring-2 ring-brandColor lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-black underline uppercase decoration-wavy">
          Login
        </h1>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={values}
          validationSchema={validate}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form className="addProductForm" onSubmit={handleSubmit}>
              <div
                className={`addProductItem ${
                  errors.email && touched.email && "border-rose-600 b0rder-4"
                } `}
              >
                <input
                  type="email"
                  placeholder="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="email"
                  value={values.email}
                />
              </div>
              <div
                className={`addProductItem ${
                  errors.password && touched.password && "border-rose-600"
                } `}
              >
                <input
                  type="password"
                  placeholder="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="password"
                  value={values.password}
                />
              </div>

              <button
                className="button
              "
                type="submit"
                disabled={disabled}
              >
                {disabled ? (
                  <div className="flex justify-center items-center">
                    Logging in..&nbsp;
                    <CircularProgress color="inherit" />
                  </div>
                ) : (
                  "Login"
                )}
              </button>
              <Toaster />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
