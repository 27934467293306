import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import Dropzone from "react-dropzone";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import serverUrl from "../../server";
import { setCollections, setProducts } from "../../state";
import "./product.css";

const validate = yup.object().shape({
  productName: yup.string().required("required"),
  productPrice: yup.string().required("required"),
  productCategory: yup.string().required("required"),
  productCollection: yup.string().required("required"),
  pictures: yup.array().min(1).required("required"),
  productType: yup.string().required("required"),
  isBestSelling: yup.string().required("required"),
});

export default function Product() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const products = useSelector((state) => state.products) || "";
  const token = useSelector((state) => state.token);
  const product = products.filter((item) => item._id === productId);
  const newProducts = products.filter((item) => item._id !== productId);
  const collectionOption = useSelector((state) => state.collectionOption);

  console.log(product);
  const values = {
    productName: product[0].productName || "",
    productPrice: product[0].productPrice || "",
    productCategory: product[0].productCategory || "",
    productCollection: product[0].productCollection || "",
    pictures: [],
    productType: product[0].productType || "",
    isBestSelling: product[0].isBestSelling || "",
  };
  const dispatch = useDispatch();
  const removeImage = (index, setFieldValue, values) => {
    const removed = [...values.pictures];
    removed.splice(index, 1);
    setFieldValue("pictures", removed);
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    console.log(values);
    setDisabled(!disabled);
    // this allows us to send form info with image
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    values.pictures.forEach((file) => {
      formData.append("pictures", file);
    });
    let fileNames = [];
    for (let i = 0; i < values.pictures.length; i++) {
      fileNames.push(values.pictures[i].name);
    }
    fileNames = fileNames.includes(undefined)
      ? values.pictures.map((file, index) => file)
      : fileNames;
    console.log(fileNames);
    formData.append("productImages", fileNames);

    const createProductResponse = await fetch(
      `${serverUrl}/api/product/edit/${product[0]._id}`,
      {
        method: "PATCH",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      }
    );
    const savedProduct = await createProductResponse.json();
    if (createProductResponse.ok) {
      console.log(savedProduct);
      // Clean Products Data for update
      toast.success("Product Edited Successfully", { position: "top-right" });
      navigate("/products");
      onSubmitProps.resetForm();
      const index = products.length + 1;
      dispatch(
        setProducts({
          products: [
            ...newProducts,
            { ...savedProduct.updatedProduct, id: index },
          ],
        })
      );
      // Group products by collection name
      let groupedProducts = {};
      let idCounter = 1;
      const newProductsData = [
        ...newProducts,
        { ...savedProduct.updatedProduct, id: index },
      ];
      newProductsData.forEach((product) => {
        if (!groupedProducts[product.productCollection]) {
          groupedProducts[product.productCollection] = {
            id: idCounter,
            collectionName: product.productCollection,
            products: [product],
          };
          idCounter++;
        } else {
          groupedProducts[product.productCollection].products.push(product);
        }
      });
      const groupedProductsByCollectionArray = Object.values(groupedProducts);
      console.log(groupedProductsByCollectionArray);
      dispatch(
        setCollections({ collections: groupedProductsByCollectionArray })
      );
      setDisabled(false);
    } else {
      console.log(savedProduct);
      setDisabled(false);
      toast.error(`${savedProduct.error}`, { position: "top-right" });
    }
  };
  return (
    <div className="newProduct">
      <h1 className="text-3xl font-semibold">Edit Product</h1>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={values}
        validationSchema={validate}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form className="addProductForm" onSubmit={handleSubmit}>
            <Dropzone
              acceptedFiles=".jpg,.jpeg,.png"
              multiple={true}
              onDrop={(acceptedFiles) => {
                setFieldValue("pictures", [
                  ...values.pictures,
                  ...acceptedFiles,
                ]);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  p="1rem"
                  border={`2px dashed `}
                  className={`addProductItem hover:cursor-pointer ${
                    values.pictures.length === 0 ? "border-rose-600" : null
                  } `}
                >
                  <input {...getInputProps()} />
                  {values.pictures.length === 0 ? (
                    <p>Add Product Pictures Here</p>
                  ) : (
                    values.pictures.map((file, index) => (
                      <FlexBetween key={index}>
                        <Typography>{file.name ?? file}</Typography>
                        <DeleteOutlineIcon
                          className="red"
                          onClick={() =>
                            removeImage(index, setFieldValue, values)
                          }
                        />
                      </FlexBetween>
                    ))
                  )}
                </Box>
              )}
            </Dropzone>
            <div
              className={`addProductItem ${
                errors.productName && touched.productName && "border-rose-600"
              } `}
            >
              <input
                type="text"
                placeholder="Product name"
                onBlur={handleBlur}
                onChange={handleChange}
                name="productName"
                value={values.productName}
              />
            </div>
            <div
              className={`addProductItem ${
                errors.productPrice && touched.productPrice && "border-rose-600"
              } `}
            >
              <input
                type="number"
                min={1000}
                placeholder="Product Price"
                onBlur={handleBlur}
                onChange={handleChange}
                name="productPrice"
                value={values.productPrice}
              />
            </div>
            <div
              className={`addProductItem ${
                errors.productType && touched.productType && "border-rose-600"
              } `}
            >
              <select
                name="productType"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.productType}
              >
                <option value="" selected>
                  Select product Type
                </option>
                <option value="hat-and-cap">Hat & Cap</option>
                <option value="top">Top (Tees and tops ) </option>
                <option value="design-with-kaizen">
                  Design With Kaizen 🎨{" "}
                </option>
                <option value="polo">Polos</option>
                <option value="shorts">Shorts</option>
                <option value="sweatshirt">Sweatshirts and Sweat tops</option>
                <option value="joggers-and-sweatpant-and-cargos">
                  Joggers & Sweatpant & Cargos
                </option>
                <option value="jacket">Jacket</option>
                <option value="hoodie">Hoodie</option>
                <option value="bag">Bag</option>
              </select>
            </div>
            <div
              className={`addProductItem ${
                errors.productCategory &&
                touched.productCategory &&
                "border-rose-600"
              } `}
            >
              <select
                name="productCategory"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.productCategory}
              >
                <option value="" selected>
                  Select product Category
                </option>
                <option value="hats-and-caps">Hats & Caps</option>
                <option value="tops">Tops</option>
                <option value="hoodies-and-jackets">Hoodies & Jackets</option>
                <option value="joggers-and-shorts">Joggers & Shorts</option>
                <option value="bags">Bags</option>
              </select>
            </div>
            <div
              className={`addProductItem ${
                errors.productCollection &&
                touched.productCollection &&
                "border-rose-600"
              } `}
            >
              <select
                name="productCollection"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.productCollection}
              >
                <option value="" selected>
                  Select product Collection
                </option>
                {collectionOption.map((collection, index) => {
                  return (
                    <option key={index} value={`${collection.collectionName}`}>
                      {
                        collection.collectionName
                          .split("-") // split the string into an array of words
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          ) // capitalize the first letter of each word
                          .join(" ") // join the words back into a single string with a space separator
                      }
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              className={`addProductItem ${
                errors.isBestSelling &&
                touched.isBestSelling &&
                "border-rose-600"
              } `}
            >
              <select
                name="isBestSelling"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.isBestSelling}
              >
                <option value="" selected>
                  Add to best selling products
                </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
            <button className="button" type="submit" disabled={disabled}>
              {disabled ? (
                <div className="flex justify-center items-center">
                  Editing Product&nbsp;
                  <CircularProgress color="inherit" />
                </div>
              ) : (
                "Edit Product"
              )}
            </button>
            <Toaster />
          </form>
        )}
      </Formik>
    </div>
  );
}

const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
