import { DeleteOutline } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Switch from "@mui/material/Switch";
import { DataGrid } from "@mui/x-data-grid";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import serverUrl from "../../server";
import { setCollections, setProducts } from "../../state";

export default function ProductList() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const token = useSelector((state) => state.token);
  const handleDelete = async (_id) => {
    const removed = products.filter((item) => {
      return item._id !== _id;
    });
    toast.success("Product Successfully Deleted", {
      position: "top-center",
    });
    dispatch(setProducts({ products: removed }));
    // Group products by collection name
    let groupedProducts = {};
    let idCounter = 1;
    removed.forEach((product) => {
      if (!groupedProducts[product.productCollection]) {
        groupedProducts[product.productCollection] = {
          id: idCounter,
          collectionName: product.productCollection,
          products: [product],
        };
        idCounter++;
      } else {
        groupedProducts[product.productCollection].products.push(product);
      }
    });
    const groupedProductsByCollectionArray = Object.values(groupedProducts);
    console.log(groupedProductsByCollectionArray);
    dispatch(setCollections({ collections: groupedProductsByCollectionArray }));
    await fetch(`${serverUrl}/api/product/delete/${_id}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        dispatch(setProducts({ products: removed }));
        // Group products by collection name
        let groupedProducts = {};
        let idCounter = 1;
        removed.forEach((product) => {
          if (!groupedProducts[product.productCollection]) {
            groupedProducts[product.productCollection] = {
              id: idCounter,
              collectionName: product.productCollection,
              products: [product],
            };
            idCounter++;
          } else {
            groupedProducts[product.productCollection].products.push(product);
          }
        });
        const groupedProductsByCollectionArray = Object.values(groupedProducts);
        console.log(groupedProductsByCollectionArray);
        dispatch(
          setCollections({ collections: groupedProductsByCollectionArray })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleBestSellingSwitch = async (_id, newAttribute) => {
    // newAttribute.stopPropagation();
    const updatedProducts = products.map((product) =>
      product._id === _id
        ? { ...product, isBestSelling: newAttribute.target.checked }
        : product
    );
    // dispatch(setProducts({ products: updatedProducts }));
    const patchDocResponse = await fetch(
      `${serverUrl}/api/product/patch/${_id}`,
      {
        method: "PATCH",
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({ isBestSelling: newAttribute.target.checked }),
      }
    );

    const savedDoc = patchDocResponse.json();
    if (patchDocResponse.ok) {
      console.log(savedDoc);
      dispatch(setProducts({ products: updatedProducts }));
      // Group products by collection name
      let groupedProducts = {};
      let idCounter = 1;
      updatedProducts.forEach((product) => {
        if (!groupedProducts[product.productCollection]) {
          groupedProducts[product.productCollection] = {
            id: idCounter,
            collectionName: product.productCollection,
            products: [product],
          };
          idCounter++;
        } else {
          groupedProducts[product.productCollection].products.push(product);
        }
      });
      const groupedProductsByCollectionArray = Object.values(groupedProducts);
      console.log(groupedProductsByCollectionArray);
      dispatch(
        setCollections({ collections: groupedProductsByCollectionArray })
      );
      toast.success("Successfully Added to Best Selling Products", {
        position: "top-right",
      });
    } else {
      toast.error("An Error Occured Try again :(", {
        position: "top-right",
      });
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "product",
      headerName: "Product",
      width: 250,
      renderCell: (params) => {
        return (
          <ProductListItem>
            <ProductListImg
              src={`${params.row.productImages?.[0]}`}
              alt="Item Image"
            />
            {params.row.productName}
          </ProductListItem>
        );
      },
    },

    {
      field: "productType",
      headerName: "Product",
      width: 150,
      valueGetter: (params) =>
        `${
          params.row.productType
            .split("-") // split the string into an array of words
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
            .join(" ") // join the words back into a single string with a space separator
        }`,
    },
    {
      field: "productPrice",
      headerName: "Price",
      width: 200,
      valueGetter: (params) => `₦${params.row.productPrice}`,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/product/" + params.row._id}>
              <ProductListEditButton>Edit</ProductListEditButton>
            </Link>
            <ProductListDeleteIcon
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
    {
      field: "isBestSelling",
      headerName: "Add to Best Selling",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Switch
              checked={params.row.isBestSelling}
              onChange={(newAttribute) =>
                handleBestSellingSwitch(params.row._id, newAttribute)
              }
            />
          </>
        );
      },
    },
  ];

  return (
    <ProductListContainer>
      {products.length < 1 ? (
        <>
          <ProductListTitleContainer>
            <ProductListTitle className="text-3xl font-bold">
              Products
            </ProductListTitle>
            <Link to="/newproduct">
              <ProductAddButton>Add New Product</ProductAddButton>
            </Link>
          </ProductListTitleContainer>
          <div className="flex justify-center items-center min-h-screen gap-3">
            <p> Seems like there's no product 👀, Try adding one... </p>
            <CircularProgress sx={{ color: "#b8a06a" }} />
          </div>
        </>
      ) : (
        <>
          <ProductListTitleContainer>
            <ProductListTitle className="text-3xl font-bold">
              Products
            </ProductListTitle>
            <Link to="/newproduct">
              <ProductAddButton>Add New Product</ProductAddButton>
            </Link>
          </ProductListTitleContainer>
          <DataGrid
            rows={products}
            disableSelectionOnClick
            columns={columns}
            pageSize={8}
            autoHeight
            checkboxSelection
            key={products.length}
          />
          <Toaster />
        </>
      )}
    </ProductListContainer>
  );
}

const ProductListContainer = styled.div`
  flex: 4;
`;

const ProductListItem = styled.div`
  display: flex;
  align-items: center;
`;
const ProductListImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;
const ProductListEditButton = styled.button`
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
`;
const ProductListDeleteIcon = styled(DeleteOutline)`
  color: red;
  cursor: pointer;
`;
const ProductListTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
const ProductListTitle = styled.h1``;
const ProductAddButton = styled.button`
  border: none;
  padding: 10px;
  background-color: #b8a06a;
  color: black;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;
