import { Box, CircularProgress, styled } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import serverUrl from "../../server";
import { setDeliveryPrices } from "../../state";
import "./NewPrice.css";
const values = {
  priceName: "",
  priceAmount: "",
};

const validate = yup.object().shape({
  priceName: yup.string().required("required"),
  priceAmount: yup.string().required("required"),
});

export default function NewPrice() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleFormSubmit = async (values, onSubmitProps) => {
    setDisabled(!disabled);
    console.log(values);
    const createNewPriceResponse = await fetch(
      `${serverUrl}/api/delivery-prices`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      }
    );
    const savedPrice = await createNewPriceResponse.json();
    if (createNewPriceResponse.ok) {
      console.log(savedPrice);
      // Clean Banners Data for update
      dispatch(setDeliveryPrices({ deliveryPrices: [] }));
      toast.success("Banner Added Successfully", { position: "top-right" });
      navigate("/delivery-prices");
      onSubmitProps.resetForm();
      setDisabled(false);
    } else {
      console.log(savedPrice);
      setDisabled(false);
      toast.error(`${savedPrice.error}`, { position: "top-right" });
    }
  };
  return (
    <div className="newProduct">
      <h1 className="text-3xl font-semibold">New Price</h1>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={values}
        validationSchema={validate}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form className="addProductForm" onSubmit={handleSubmit}>
            <div
              className={`addProductItem ${
                errors.priceName && touched.priceName && "border-rose-600"
              } `}
            >
              <input
                type="text"
                placeholder="Price name"
                onBlur={handleBlur}
                onChange={handleChange}
                name="priceName"
                value={values.priceName}
              />
            </div>
            <div
              className={`addProductItem ${
                errors.priceAmount && touched.priceAmount && "border-rose-600"
              } `}
            >
              <input
                type="text"
                placeholder="Add price amount"
                onBlur={handleBlur}
                onChange={handleChange}
                name="priceAmount"
                value={values.priceAmount}
              />
            </div>

            <button className="button" type="submit" disabled={disabled}>
              {disabled ? (
                <div className="flex justify-center items-center">
                  Creating Price...&nbsp;
                  <CircularProgress color="inherit" />
                </div>
              ) : (
                "Create Price"
              )}
            </button>
            <Toaster />
          </form>
        )}
      </Formik>
    </div>
  );
}

const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
