import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { setLogout } from "../../state";

export default function Topbar() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setLogout());
  };
  return (
    <TopbarContainer>
      <TopbarWrapper>
        <div className="topLeft">
          <TopbarLogo className="logo">Kaizen Admin</TopbarLogo>
        </div>
        <div className="">
          <p className="text-brandColor text-md">
            {user?.email ? user.email : <Navigate to={"/login"} />}
          </p>
          {user && (
            <button
              className="bg-brandColor py-2 px-3 rounded-xl shadow-xl hover:bg-white"
              onClick={handleLogout}
            >
              Log out
            </button>
          )}
        </div>
      </TopbarWrapper>
    </TopbarContainer>
  );
}

const TopbarContainer = styled.div`
  width: 100%;
  height: 100px;
  background-color: black;
  position: sticky;
  top: 0;
  z-index: 999;
`;
const TopbarWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TopbarLogo = styled.span`
  font-weight: bold;
  font-size: 30px;
  color: #b8a06a;
  cursor: pointer;
`;
