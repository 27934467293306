import { LineStyle, PermIdentity, Storefront } from "@mui/icons-material";
import SellIcon from "@mui/icons-material/Sell";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
export default function Sidebar() {
  const location = useLocation();

  return (
    <SidebarContainer>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarTitle>Dashboard</SidebarTitle>
          <SidebarList>
            <SidebarNavLink to="/">
              <SidebarListItem active={location.pathname === "/"}>
                <SidebarLineStyleIcon />
                Home
              </SidebarListItem>
            </SidebarNavLink>
            <SidebarNavLink to="/products">
              <SidebarListItem active={location.pathname === "/products"}>
                <SidebarStoreFrontIcon />
                Products
              </SidebarListItem>
            </SidebarNavLink>
            <SidebarNavLink to="/collections">
              <SidebarListItem active={location.pathname === "/collections"}>
                <SidebarPermIdentity />
                Collections
              </SidebarListItem>
            </SidebarNavLink>
            <SidebarNavLink to="/featured-brands">
              <SidebarListItem
                active={location.pathname === "/featured-brands"}
              >
                <SidebarStoreFrontIcon />
                Featured Brands
              </SidebarListItem>
            </SidebarNavLink>
            <SidebarNavLink to="/banners">
              <SidebarListItem active={location.pathname === "/banners"}>
                <SidebarViewCarouselIcon />
                Banner
              </SidebarListItem>
            </SidebarNavLink>
            <SidebarNavLink to="/best-selling">
              <SidebarListItem active={location.pathname === "/best-selling"}>
                <SidebarSellIcon />
                Best Selling
              </SidebarListItem>
            </SidebarNavLink>
            <SidebarNavLink to="/delivery-prices">
              <SidebarListItem
                active={location.pathname === "/delivery-prices"}
              >
                <SidebarLocalShippingIcon />
                Delivery Prices
              </SidebarListItem>
            </SidebarNavLink>
          </SidebarList>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

const SidebarContainer = styled.div`
  flex: 1 !important;
  height: calc(100vh - 70px);
  background-color: black;
  position: sticky;
  top: 70px;
`;
const SidebarWrapper = styled.div`
  padding: 1rem;
  color: #555;
`;
const SidebarMenu = styled.div`
  margin-bottom: 1rem;
`;
const SidebarTitle = styled.h3`
  font-size: 15px;
  color: rgb(187, 186, 186);
  margin-bottom: 2.2rem;
`;
const SidebarList = styled.ul`
  list-style: none;
  padding: 5px;
`;
const SidebarListItem = styled.li`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  ${(props) =>
    props.active
      ? `
    background-color: #b8a06a;
    color: black;
    transform: scale(1);
  `
      : `
  color: #b8a06a;
      `}
  &:hover {
    background-color: #b8a06a;
    color: black;
    transform: scale(1);
  }
`;
const SidebarNavLink = styled(NavLink)`
  text-decoration: none;
`;
const SidebarLineStyleIcon = styled(LineStyle)`
  margin-right: 5px;
  font-size: 20px !important;
`;
const SidebarStoreFrontIcon = styled(Storefront)`
  margin-right: 5px;
  font-size: 20px !important;
`;
const SidebarPermIdentity = styled(PermIdentity)`
  margin-right: 5px;
  font-size: 20px !important;
`;
const SidebarViewCarouselIcon = styled(ViewCarouselIcon)`
  margin-right: 5px;
  font-size: 20px !important;
`;
const SidebarSellIcon = styled(SellIcon)`
  margin-right: 5px;
  font-size: 20px !important;
`;
const SidebarLocalShippingIcon = styled(LocalShippingIcon)`
  margin-right: 5px;
  font-size: 20px !important;
`;
