import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import Dropzone from "react-dropzone";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import serverUrl from "../../server";
import { setBanners } from "../../state";
import "./EditBanner.css";

const validate = yup.object().shape({
  bannerName: yup.string().required("required"),
  bannerDescription: yup.string().required("required"),
  bannerCollection: yup.string().required("required"),
  pictures: yup.array().min(1).required("required"),
});

export default function EditBanner() {
  const { bannerID } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const token = useSelector((state) => state.token);
  const collectionOption = useSelector((state) => state.collectionOption);

  const banners = useSelector((state) => state.banners) || "";
  const banner = banners.filter((item) => item._id === bannerID);
  const newBanners = banners.filter((item) => item._id !== bannerID);
  console.log(banner);
  const values = {
    bannerName: banner[0].bannerName || "",
    bannerDescription: banner[0].bannerDescription || "",
    bannerCollection: banner[0].bannerCollection || "",
    pictures: "",
  };
  const dispatch = useDispatch();
  const removeImage = (index, setFieldValue) => {
    const indexedImage = values.pictures[index];
    const removed = values.pictures.filter(
      (file) => file.name !== indexedImage.name
    );
    return setFieldValue("pictures", removed);
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    console.log(values);
    setDisabled(!disabled);
    // this allows us to send form info with image
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    values.pictures.forEach((file) => {
      formData.append("pictures", file);
    });

    const createEditBannerResponse = await fetch(
      `${serverUrl}/api/banner/edit/${banner[0]._id}`,
      {
        method: "PATCH",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      }
    );
    const savedBanner = await createEditBannerResponse.json();
    if (createEditBannerResponse.ok) {
      console.log(savedBanner);
      // Clean Products Data for update
      toast.success("Banner Edited Successfully", { position: "top-right" });
      navigate("/banners");
      onSubmitProps.resetForm();
      const index = banners.length + 1;
      dispatch(
        setBanners({
          banners: [...newBanners, { ...savedBanner.updatedBanner, id: index }],
        })
      );

      setDisabled(false);
    } else {
      console.log(savedBanner);
      setDisabled(false);
      toast.error(`${savedBanner.error}`, { position: "top-right" });
    }
  };
  return (
    <div className="newProduct">
      <h1 className="text-3xl font-semibold">Edit Banner</h1>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={values}
        validationSchema={validate}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form className="addProductForm" onSubmit={handleSubmit}>
            <Dropzone
              acceptedFiles=".jpg,.jpeg,.png"
              multiple={true}
              onDrop={(acceptedFiles) => {
                setFieldValue("pictures", [
                  ...values.pictures,
                  ...acceptedFiles,
                ]);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  p="1rem"
                  border={`2px dashed `}
                  className={`addProductItem hover:cursor-pointer ${
                    values.pictures.length === 0 ? "border-rose-600" : null
                  } `}
                >
                  <input {...getInputProps()} />
                  {values.pictures.length === 0 ? (
                    <p>Add Product Pictures Here</p>
                  ) : (
                    values.pictures.map((file, index) => (
                      <FlexBetween key={index}>
                        <Typography>{file.name ?? file}</Typography>
                        <DeleteOutlineIcon
                          className="red"
                          onClick={() => removeImage(index, setFieldValue)}
                        />
                      </FlexBetween>
                    ))
                  )}
                </Box>
              )}
            </Dropzone>
            <div
              className={`addProductItem ${
                errors.bannerName && touched.bannerName && "border-rose-600"
              } `}
            >
              <input
                type="text"
                placeholder="Banner name"
                onBlur={handleBlur}
                onChange={handleChange}
                name="bannerName"
                value={values.bannerName}
              />
            </div>

            <div
              className={`addProductItem ${
                errors.bannerDescription &&
                touched.bannerDescription &&
                "border-rose-600"
              } `}
            >
              <input
                type="text"
                placeholder="Banner Description"
                onBlur={handleBlur}
                onChange={handleChange}
                name="bannerDescription"
                value={values.bannerDescription}
              />
            </div>

            <div
              className={`addProductItem ${
                errors.bannerCollection &&
                touched.bannerCollection &&
                "border-rose-600"
              } `}
            >
              <select
                name="bannerCollection"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bannerCollection}
              >
                <option value="" selected>
                  Select Banner Collection
                </option>
                {collectionOption.map((collection, index) => {
                  return (
                    <option key={index} value={`${collection.collectionName}`}>
                      {
                        collection.collectionName
                          .split("-") // split the string into an array of words
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          ) // capitalize the first letter of each word
                          .join(" ") // join the words back into a single string with a space separator
                      }
                    </option>
                  );
                })}
              </select>
            </div>
            <button className="button" type="submit" disabled={disabled}>
              {disabled ? (
                <div className="flex justify-center items-center">
                  Editing Banner...&nbsp;
                  <CircularProgress color="inherit" />
                </div>
              ) : (
                "Edit Banner"
              )}
            </button>
            <Toaster />
          </form>
        )}
      </Formik>
    </div>
  );
}

const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
