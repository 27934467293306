import { Box, CircularProgress, styled } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import serverUrl from "../../server";
import { setDeliveryPrices } from "../../state";
import "./EditPrice.css";

const validate = yup.object().shape({
  priceName: yup.string().required("required"),
  priceAmount: yup.string().required("required"),
});

export default function EditPrice() {
  const { priceID } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const token = useSelector((state) => state.token);
  const deliveryPrices = useSelector((state) => state.deliveryPrices) || "";
  const deliveryPrice = deliveryPrices.filter((item) => item._id === priceID);
  const newDeliveryPrices = deliveryPrices.filter(
    (item) => item._id !== priceID
  );
  console.log(deliveryPrice);
  const values = {
    priceName: deliveryPrice[0].priceName || "",
    priceAmount: deliveryPrice[0].priceAmount || "",
  };
  const dispatch = useDispatch();
  const removeImage = (index, setFieldValue) => {
    const indexedImage = values.pictures[index];
    const removed = values.pictures.filter(
      (file) => file.name !== indexedImage.name
    );
    return setFieldValue("pictures", removed);
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    console.log(values);
    setDisabled(!disabled);
    const createEditPriceResponse = await fetch(
      `${serverUrl}/api/delivery-prices/patch/${deliveryPrice[0]._id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      }
    );
    const savedPrice = await createEditPriceResponse.json();
    if (createEditPriceResponse.ok) {
      console.log(savedPrice);
      // Clean Products Data for update
      toast.success("Price Edited Successfully", { position: "top-right" });
      navigate("/delivery-prices");
      onSubmitProps.resetForm();
      const index = deliveryPrices.length + 1;
      console.log(savedPrice.updatedPrice);
      dispatch(
        setDeliveryPrices({
          deliveryPrices: [
            ...newDeliveryPrices,
            { ...savedPrice.updatedPrice, id: index },
          ],
        })
      );

      setDisabled(false);
    } else {
      console.log(savedPrice);
      setDisabled(false);
      toast.error(`${savedPrice.error}`, { position: "top-right" });
    }
  };
  return (
    <div className="newProduct">
      <h1 className="text-3xl font-semibold">Edit Price</h1>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={values}
        validationSchema={validate}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form className="addProductForm" onSubmit={handleSubmit}>
            <div
              className={`addProductItem ${
                errors.priceName && touched.priceName && "border-rose-600"
              } `}
            >
              <input
                type="text"
                placeholder={`${values.priceName || "Price name"}`}
                onBlur={handleBlur}
                onChange={handleChange}
                name="priceName"
                value={values.priceName}
              />
            </div>

            <div
              className={`addProductItem ${
                errors.priceAmount && touched.priceAmount && "border-rose-600"
              } `}
            >
              <input
                type="text"
                placeholder="Banner Description"
                onBlur={handleBlur}
                onChange={handleChange}
                name="priceAmount"
                value={values.priceAmount}
              />
            </div>

            <button className="button" type="submit" disabled={disabled}>
              {disabled ? (
                <div className="flex justify-center items-center">
                  Editing Price...&nbsp;
                  <CircularProgress color="inherit" />
                </div>
              ) : (
                "Edit Price"
              )}
            </button>
            <Toaster />
          </form>
        )}
      </Formik>
    </div>
  );
}

const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
