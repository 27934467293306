import { DeleteOutline } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { setBanners } from "../../state";
import serverUrl from "../../server";

export default function BannerList() {
  const dispatch = useDispatch();
  const banners = useSelector((state) => state.banners);
  const token = useSelector((state) => state.token);
  const handleDelete = async (_id) => {
    const removed = banners.filter((item) => {
      return item._id !== _id;
    });
    toast.success("Banner Successfully Deleted", {
      position: "top-center",
    });
    dispatch(setBanners({ banners: removed }));
    await fetch(`${serverUrl}/api/banner/delete/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        headers: { Authorization: `Bearer ${token}` },
      },
    })
      .then((response) => {
        if (!response.ok) {
          toast.error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        dispatch(setBanners({ banners: [] }));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "bannerName",
      headerName: "Banner Name",
      width: 200,
      renderCell: (params) => {
        return (
          <ProductListItem>
            <ProductListImg
              src={`${params.row.bannerImages[0]}`}
              alt="Item Image"
            />
            {params.row.bannerName}
          </ProductListItem>
        );
      },
    },

    {
      field: "bannerDescription",
      headerName: "Description",
      width: 400,
      valueGetter: (params) =>
        `${
          params.row.bannerDescription
            .split("-") // split the string into an array of words
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
            .join(" ") // join the words back into a single string with a space separator
        }`,
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/banner/" + params.row._id}>
              <ProductListEditButton>Edit</ProductListEditButton>
            </Link>
            <ProductListDeleteIcon
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <ProductListContainer>
      {banners.length < 1 ? (
        <>
          <ProductListTitleContainer>
            <ProductListTitle className="text-3xl font-bold">
              Banner Items
            </ProductListTitle>
            <Link to="/newbanner">
              <ProductAddButton>Add New Banner Item</ProductAddButton>
            </Link>
          </ProductListTitleContainer>
          <div className="flex justify-center items-center min-h-screen gap-3">
            <p> Seems like there's no Banner Item 👀, Try adding one... </p>
            <CircularProgress sx={{ color: "#b8a06a" }} />
          </div>
        </>
      ) : (
        <>
          <ProductListTitleContainer>
            <ProductListTitle className="text-3xl font-bold">
              Banner Items
            </ProductListTitle>
            <Link to="/newbanner">
              <ProductAddButton>Add New Banner Item</ProductAddButton>
            </Link>
          </ProductListTitleContainer>
          <DataGrid
            rows={banners}
            disableSelectionOnClick
            columns={columns}
            pageSize={8}
            autoHeight
            checkboxSelection
          />
          <Toaster />
        </>
      )}
    </ProductListContainer>
  );
}

const ProductListContainer = styled.div`
  flex: 4;
`;

const ProductListItem = styled.div`
  display: flex;
  align-items: center;
`;
const ProductListImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;
const ProductListEditButton = styled.button`
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
`;
const ProductListDeleteIcon = styled(DeleteOutline)`
  color: red;
  cursor: pointer;
`;
const ProductListTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
const ProductListTitle = styled.h1``;
const ProductAddButton = styled.button`
  border: none;
  padding: 10px;
  background-color: #b8a06a;
  color: black;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;
