let serverUrl;

if (process.env.NODE_ENV === "production") {
  serverUrl = "https://kaizen-v2-backend.onrender.com";
} else {
  serverUrl = "http://localhost:6001";
}

// Use serverUrl to make API calls in your code
export default serverUrl;
