import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import EditBanner from "./components/EditBanner/EditBanner";
import NewBanner from "./components/newBanner/NewBanner";
import NewPrice from "./components/newPrice/NewPrice";
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import BannerList from "./pages/BannerList/BannerList";
import BestSelling from "./pages/BestSelling/BestSelling";
import Collection from "./pages/Collection/Collection";
import CollectionProductList from "./pages/CollectionProductList/CollectionProductList";
import DeliveryPricesList from "./pages/DeliveryPricesList/DeliveryPricesList";
import FeaturedBrands from "./pages/FeaturedBrands/FeaturedBrands";
import Login from "./pages/Login/Login";
import Home from "./pages/home/Home";
import NewCollection from "./pages/newCollection/NewCollection";
import NewProduct from "./pages/newProduct/NewProduct";
import Product from "./pages/product/Product";
import ProductList from "./pages/productList/ProductList";
import serverUrl from "./server";
import {
  setBanners,
  setCollectionOption,
  setCollections,
  setDeliveryPrices,
  setProducts,
} from "./state";
import EditPrice from "./components/EditPrice/EditPrice";

function App() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const banners = useSelector((state) => state.banners);
  const collectionOption = useSelector((state) => state.collectionOption);
  const isAuth = Boolean(useSelector((state) => state.token));
  const deliveryPrices = useSelector((state) => state.deliveryPrices);

  console.log(products);
  console.log(banners);
  console.log(collectionOption);

  useEffect(() => {
    if (products.length < 1) {
      async function fetchProducts(link) {
        await fetch(`${link}`, {
          headers: {
            "Content-Type": "application/json",
            origin: "http://localhost:8080",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            dispatch(
              setProducts({
                products: data.map((product, index) => {
                  return {
                    id: +index + 1,
                    _id: product._id,
                    productCategory: product.productCategory,
                    productCollection: product.productCollection,
                    productImages: product.productImages,
                    productName: product.productName,
                    productPrice: product.productPrice,
                    productType: product.productType,
                    isBestSelling: product.isBestSelling,
                  };
                }),
              })
            );
            const productsData = data.map((product, index) => {
              return {
                id: +index + 1,
                _id: product._id,
                productCategory: product.productCategory,
                productCollection: product.productCollection,
                productImages: product.productImages,
                productName: product.productName,
                productPrice: product.productPrice,
                productType: product.productType,
                isBestSelling: product.isBestSelling,
              };
            });
            // Group products by collection name
            let groupedProducts = {};
            let idCounter = 1;
            productsData.forEach((product) => {
              if (!groupedProducts[product.productCollection]) {
                groupedProducts[product.productCollection] = {
                  id: idCounter,
                  collectionName: product.productCollection,
                  products: [product],
                };
                idCounter++;
              } else {
                groupedProducts[product.productCollection].products.push(
                  product
                );
              }
            });
            const groupedProductsByCollectionArray =
              Object.values(groupedProducts);
            console.log(groupedProductsByCollectionArray);
            dispatch(
              setCollections({ collections: groupedProductsByCollectionArray })
            );
          });
      }
      fetchProducts(`${serverUrl}/api/product`);
    }
    if (banners.length < 1) {
      async function fetchBanners(link) {
        await fetch(`${link}`, {
          headers: {
            "Content-Type": "application/json",
            origin: "http://localhost:8080",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            dispatch(
              setBanners({
                banners: data.map((banner, index) => {
                  return {
                    id: +index + 1,
                    _id: banner._id,
                    bannerName: banner.bannerName,
                    bannerImages: banner.bannerImages,
                    bannerDescription: banner.bannerDescription,
                    bannerCollection: banner.bannerCollection,
                  };
                }),
              })
            );
          });
      }
      fetchBanners(`${serverUrl}/api/banner`);
    }
    if (deliveryPrices.length < 1) {
      async function fetchDeliveryPrices(link) {
        await fetch(`${link}`, {
          headers: {
            "Content-Type": "application/json",
            origin: "http://localhost:8080",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            dispatch(
              setDeliveryPrices({
                deliveryPrices: data?.map((price, index) => {
                  return {
                    id: +index + 1,
                    _id: price._id,
                    ...price,
                  };
                }),
              })
            );
          });
      }
      fetchDeliveryPrices(`${serverUrl}/api/delivery-prices`);
    }
    if (collectionOption.length < 1) {
      async function fetchCollectionOption(link) {
        await fetch(`${link}`, {
          headers: {
            "Content-Type": "application/json",
            origin: "http://localhost:8080",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            dispatch(
              setCollectionOption({
                collectionOption: data?.collections?.map(
                  (collection, index) => {
                    return {
                      id: +index + 1,
                      _id: collection._id,
                      ...collection,
                    };
                  }
                ),
              })
            );
          });
      }
      fetchCollectionOption(`${serverUrl}/api/collection`);
    }
  }, [
    collectionOption.length,
    banners.length,
    deliveryPrices.length,
    products,
    deliveryPrices,
    products.length,
    dispatch,
    banners,
    collectionOption,
  ]);
  return (
    <>
      <Topbar />
      <div className="container">
        <Sidebar />
        <div className="second-container">
          <Routes>
            <Route
              path="/"
              element={isAuth ? <Home /> : <Navigate to="/login" />}
            />
            <Route
              path="/collections"
              element={isAuth ? <Collection /> : <Navigate to="/login" />}
            />
            <Route
              path="/collections/:collectionName"
              element={
                isAuth ? <CollectionProductList /> : <Navigate to="/login" />
              }
            />

            <Route
              path="/banners"
              element={isAuth ? <BannerList /> : <Navigate to="/login" />}
            />
            {/* For Future Usage  */}
            {/* <Route path="/user/:userId" element={<User />} /> */}
            {/* <Route path="/newUser" element={<NewUser />} /> */}
            <Route
              path="/products"
              element={isAuth ? <ProductList /> : <Navigate to="/login" />}
            />
            <Route
              path="/product/:productId"
              element={isAuth ? <Product /> : <Navigate to="/login" />}
            />
            <Route
              path="/banner/:bannerID"
              element={isAuth ? <EditBanner /> : <Navigate to="/login" />}
            />
            <Route
              path="/delivery-prices/:priceID"
              element={isAuth ? <EditPrice /> : <Navigate to="/login" />}
            />
            <Route
              path="/newproduct"
              element={isAuth ? <NewProduct /> : <Navigate to="/login" />}
            />
            <Route
              path="/newcollection"
              element={isAuth ? <NewCollection /> : <Navigate to="/login" />}
            />
            <Route
              path="/newbanner"
              element={isAuth ? <NewBanner /> : <Navigate to="/login" />}
            />
            <Route
              path="/newprice"
              element={isAuth ? <NewPrice /> : <Navigate to="/login" />}
            />
            <Route
              path="/featured-brands"
              element={isAuth ? <FeaturedBrands /> : <Navigate to="/login" />}
            />
            <Route
              path="/best-selling"
              element={isAuth ? <BestSelling /> : <Navigate to="/login" />}
            />
            <Route
              path="/delivery-prices"
              element={
                isAuth ? <DeliveryPricesList /> : <Navigate to="/login" />
              }
            />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
