import { CircularProgress } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import serverUrl from "../../server";
import { setCollectionOption } from "../../state";
import "./newCollection.css";
const values = {
  collectionName: "",
};

const validate = yup.object().shape({
  collectionName: yup.string().required("required"),
});

export default function NewCollection() {
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  // const removeImage = (index, setFieldValue, values) => {
  //   const removed = [...values.pictures];
  //   removed.splice(index, 1);
  //   setFieldValue("pictures", removed);
  // };

  const handleFormSubmit = async (values, onSubmitProps) => {
    setDisabled(!disabled);
    // this allows us to send form info with image
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    // console.log(formData);
    const createCollectionResponse = await fetch(
      `${serverUrl}/api/collection`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      }
    );
    const savedCollection = await createCollectionResponse.json();
    if (createCollectionResponse.ok) {
      // Clean Collections Data for update
      dispatch(setCollectionOption({ collectionOption: [] }));
      toast.success("Collection Added Successfully", { position: "top-right" });
      navigate("/collections");
      onSubmitProps.resetForm();
      setDisabled(false);
    } else {
      // console.log(savedCollection);
      setDisabled(false);
      toast.error(`${savedCollection.error}`, { position: "top-right" });
    }
  };
  return (
    <div className="newProduct">
      <h1 className="text-3xl font-semibold">New Collection</h1>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={values}
        validationSchema={validate}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form className="addProductForm" onSubmit={handleSubmit}>
            <div
              className={`addProductItem ${
                errors.collectionName &&
                touched.collectionName &&
                "border-rose-600"
              } `}
            >
              <input
                type="text"
                placeholder="Collection name"
                onBlur={handleBlur}
                onChange={handleChange}
                name="collectionName"
                value={values.collectionName}
              />
            </div>

            <button className="button" type="submit" disabled={disabled}>
              {disabled ? (
                <div className="flex justify-center items-center">
                  Creating Collection&nbsp;
                  <CircularProgress color="inherit" />
                </div>
              ) : (
                "Create Collection"
              )}
            </button>
            <Toaster />
          </form>
        )}
      </Formik>
    </div>
  );
}

// const FlexBetween = styled(Box)({
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
// });
