import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  banners: [],
  products: [],
  collections: [],
  collectionOption: [],
  deliveryPrices: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.products = [];
      state.collections = [];
      state.banners = [];
      state.collectionOption = [];
    },
    setProducts: (state, action) => {
      state.products = action.payload.products;
    },
    setCollections: (state, action) => {
      state.collections = action.payload.collections;
    },
    setBanners: (state, action) => {
      state.banners = action.payload.banners;
    },
    setDeliveryPrices: (state, action) => {
      state.deliveryPrices = action.payload.deliveryPrices;
    },
    setCollectionOption: (state, action) => {
      state.collectionOption = action.payload.collectionOption;
    },
  },
});

export const {
  setLogin,
  setLogout,
  setProducts,
  setCollections,
  setBanners,
  setCollectionOption,
  setDeliveryPrices,
} = authSlice.actions;
export default authSlice.reducer;
